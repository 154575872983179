<template>
  <div>
    <!-- Start card wrapper -->
    <v-card class="SearchWrapper">
      <v-layout width="100%" row :algin-start="mdUp" :align-center="!mdUp">
        <!-- Start search title -->
        <v-flex xs12 sm3 style="margin-right: 1%">
          <p class="FieldLabel"></p>
          <!-- End search title -->
          <!-- Start search text field -->
          <v-text-field
            class="custom-placeholder"
            outlined
            dense
            background-color="#FAFAFA"
            clearable
            v-model="sSearch"
            append-icon="mdi-magnify"
            :placeholder="'Búsqueda general...'"
          >
          </v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <!-- End search text field -->
      </v-layout>
    </v-card>
    <!-- End card wrapper -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      areaChosen: null,
      statusChosen: null,
      // store search input
      sSearch: "",
      bMenuFinal: false,
      bMenuInitial: false,
      dateStart: null,
      dateEnd: null,
    };
  },
  beforeMount() {
    this.$store.commit("setSearch", "");
    this.$store.commit("setStatus", null);
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);
    //  this.$store.commit("setDebtDays", null);
  },
  watch: {
    statusChosen: function () {
      this.$store.commit("setStatus", this.statusChosen);
    },
    areaChosen: function () {
      this.$store.commit("setArea", this.areaChosen);
    },
    dateStart: function () {
      this.dateEnd = null;
      this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function () {
      this.$store.commit("setEndDate", this.dateEnd);
    },
    // set state to local search
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 800),
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
};
</script>
<style>
.FieldLabel {
  text-align: left;
  font: normal normal normal 12px/14px "Poppins", sans-serif;
  margin-bottom: 0px !important;
  margin-left: 20px;
  z-index: 100;
  color: #008b83;
  opacity: 1;
}
.custom-placeholder input::placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
.custom-placeholder input::-moz-placeholder{
  text-align: left;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
</style>
<style
  scoped>
.searchTextField {
  margin-top: -28px;
}
.SearchWrapper {
  max-height: 80px;
  padding-left: 10px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px transparent !important;
  border-radius: 30px !important;
  width: 100%;
}
</style>